import { render, staticRenderFns } from "./Orders.vue?vue&type=template&id=3625ee6e&"
import script from "./Orders.vue?vue&type=script&lang=js&"
export * from "./Orders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBreadcrumbs,VCard,VCol,VContainer,VDivider,VIcon,VPagination,VResponsive,VRow,VSheet,VSimpleTable})
