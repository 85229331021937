<template>
  <v-responsive>
    <SubMenuMypageMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuMypageMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>
          
          <SubMenuMypage></SubMenuMypage>





        </v-col>
        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>



          <v-responsive class="mt-10">
            <h3>충전/사용내역</h3>
              <v-sheet outlined class=" mt-1 ">
                  <v-simple-table >
                    <template >
                      <thead>
                        <tr>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            날짜
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            구분
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            내용
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            포인트
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in items"
                          :key="item.date"
                        >
                          <td class="text-center">{{$moment(item.date).format("YYYY-MM-DD")}}</td>
                          <td class="text-center">{{item.fields.activity_type}}</td>
                          <td class="text-center">{{item.fields.content}}</td>
                          <td class="text-center">
                           {{$addCommas(item.fields.point)}} 원
                          </td>
                          
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
              </v-sheet>
          </v-responsive>


    <v-pagination
    class="mt-10
    "
      v-model="page"
      :length="lastpage"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
    ></v-pagination>






        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
import SubMenuMypage from '../components/SubMenuMypage.vue';
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Mypage",

  components: {
    SubMenuMypage
    //HelloWorld,
  },
  data: () => ({
    breadcrumbs: [
      { text: "홈" },
      { text: "마이페이지" },
      { text: "충전/사용내역" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
    selectedItem: 0,


      items: [],

      thispage: null,
      lastpage: null,
      total: null,

      loading: true,
      firstLoad: true,

        page: 1,


    
        freeboard: [
          {
            date: '2021.08.10',
            type: '조합하기',
            content: '반자동조합기(고정수)',
            point: '-200',
          },
          {
            date: '2021.08.10',
            type: '조합하기',
            content: '반자동조합기(고정수)',
            point: '-200',
          },
          {
            date: '2021.08.10',
            type: '조합하기',
            content: '반자동조합기(고정수)',
            point: '-200',
          },
          {
            date: '2021.08.10',
            type: '로그인충전',
            content: '회원가입을 축하드립니다.',
            point: '100',
          },
        ],


  }),
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getList();
    console.log(this.firstLoad);
  },
  
  watch: {
    
    page() {
      console.log("watch page");

      //카테고리를 이동하면서 발생되는 이벤트는 초기 로딩이 완료 된 후에 작동
      if (this.firstLoad === false) {
        this.items = [];
        this.getList();
      }
    },

  },

  
  methods: {
    getLottoArray(lottoNumStr) {
      return lottoNumStr.split(',');
    },
    getType(type) {
      if(type=='paid'){
        return '유료';
      }
      if(type=='free'){
        return '무료';
      }
    },


    nextPage() {
      if (this.thispage >= this.lastpage) return false;

      let nextpage = this.thispage + 1;

      //console.log(nextpage);
      this.getList(nextpage);
    },

    getList(page) {
      if (!page) page = this.page;
      this.loading = true;

      this.$http
        .get(
          "/api/v1/activity/?page=" +
            page +
            "&count=" +
            10 +
            "&parent=" +
            ""
        )
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            if (this.firstLoad) {
              this.items = res.data.items;
            } else {
              this.items = this.items.concat(res.data.items);
            }

            this.thispage = res.data.thispage;
            this.lastpage = res.data.lastpage;
            this.total = res.data.total;
            this.uid = res.data.uid;
            this.roles = res.data.roles;

            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

    },

  },


};
</script>
<style></style>
